import { Component, OnInit, Input } from '@angular/core';
import { Order } from 'app/shared/model/order';

@Component({
  selector: 'app-app-polling-my-order-parameters',
  templateUrl: './app-polling-my-order-parameters.component.html',
  styleUrls: ['./app-polling-my-order-parameters.component.css']
})
export class AppPollingMyOrderParametersComponent implements OnInit {
  @Input()
  order: Order;

  parameters: any = new Object();
  parameterKeys: string[] = [];

  constructor() { }

  ngOnInit(): void {
    this.parameters = JSON.parse(this.order.parameters);
    this.parameterKeys = Object.keys(this.parameters);
  }
}
