import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { User } from 'app/shared/model/user';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
})
export class HomePageComponent implements OnInit {
  user: User;
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.userSubject.pipe(take(1)).subscribe((user) => {
      this.initializeComponent(user);

    });
  }

  initializeComponent(user: User) {
    this.user = user;
  }
}
