<c-content>
  <section>

    <h3> Supported Versions </h3>
    We are currently supporting Harmonized UDS version 2.2 and 2.3

    <h3>1. Install .NET Core SDK</h3>
    To run the Agent you are required to have Aspnet Core Runtime 7.0 (x86)
    installed, you can find it
    <a href="https://dotnet.microsoft.com/en-us/download/dotnet/thank-you/runtime-aspnetcore-7.0.20-windows-x86-installer"
      target="_blank">
      here
    </a>

    <h3>2. Install .Net Runtime Desktop 7.0 (X86)</h3>
    This package can be found
    <a href="https://dotnet.microsoft.com/en-us/download/dotnet/thank-you/runtime-desktop-7.0.20-windows-x86-installer"
      target="_blank">here</a>
  </section>

  <section>
    <h3>2. Install VCI Drivers</h3>
    Make sure you have the drivers installed for your respective VCI.<br />
    If you have a Kvaser VCI find your driver
    <a href="https://www.kvaser.com/download/" target="_blank">
      here
    </a>
  </section>
  <section>
    <h3>3. Install Agent</h3>
    Download the agent, unpack it and run
    <i>TDQured.Agent.Wpf</i>
    <p>
      Contact us if your agent does not show up after logging in
    </p>
    <br />
    Click
    <a [href]="downloadURL">here</a> to download
  </section>
</c-content>