import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiMessage } from 'app/modules/shared/api/api-message';

@Injectable({
  providedIn: 'root'
})
export class ApiMessageService {
  getMessage: Observable<ApiMessage>;
  putMessage: Observable<ApiMessage>;
  postMessage: Observable<ApiMessage>;
  deleteMessage: Observable<ApiMessage>;
  anyMessage: Observable<ApiMessage>;

  private getMessageSubject: Subject<ApiMessage>;
  private putMessageSubject: Subject<ApiMessage>;
  private postMessageSubject: Subject<ApiMessage>;
  private deleteMessageSubject: Subject<ApiMessage>;
  private anyMessageSubject: Subject<ApiMessage>;

  constructor() {
    this.getMessageSubject = new Subject<ApiMessage>();
    this.putMessageSubject = new Subject<ApiMessage>();
    this.postMessageSubject = new Subject<ApiMessage>();
    this.deleteMessageSubject = new Subject<ApiMessage>();
    this.anyMessageSubject = new Subject<ApiMessage>();

    this.getMessage = this.getMessageSubject.asObservable();
    this.putMessage = this.putMessageSubject.asObservable();
    this.postMessage = this.postMessageSubject.asObservable();
    this.deleteMessage = this.deleteMessageSubject.asObservable();
    this.anyMessage = this.anyMessageSubject.asObservable();
  }

  sendGetMessage(): ApiMessage {
    return this.sendMessage(this.getMessageSubject);
  }

  sendPutMessage(): ApiMessage {
    return this.sendMessage(this.putMessageSubject);
  }

  sendPostMessage(): ApiMessage {
    return this.sendMessage(this.postMessageSubject);
  }

  sendDeleteMessage(): ApiMessage {
    return this.sendMessage(this.deleteMessageSubject);
  }

  private sendMessage(subject: Subject<ApiMessage>): ApiMessage {
    const apiMessage = new ApiMessage();
    subject.next(apiMessage);
    this.anyMessageSubject.next(apiMessage);
    return apiMessage;
  }
}
