import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { ApiProxyService } from 'app/services/api.service';
import { map } from 'rxjs/operators';
import { Token } from '../shared/token';
import { User } from 'app/shared/model/user';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userSubject: Subject<any> = new Subject<JSON>();
  tdqureUser: User;

  private refreshInterval: number;
  private userInterval: number;

  constructor(private router: Router, private apiService: ApiProxyService) {
    this.userInterval = window.setInterval(() => {
      if (this.tdqureUser) {
        this.getProfile().subscribe({
          next: (arg) => { },
        });
      }
    }, 1000 * 20);
  }

  login(userName: string, password: string): Observable<void> {
    const body = new HttpParams()
      .set('username', userName)
      .set('password', password)
      .set('grant_type', 'password')
      .set('client_id', 'web.client')
      .set('scope', 'openid webapi profile offline_access');

    return this.apiService
      .postLogin<any, Token>(environment.apiUrl + 'connect/token', body)
      .pipe(
        map((token) => {
          this.apiService.setToken(token);
        })
      );
  }

  getProfile(): Observable<void> {
    return this.apiService.get<User>(environment.apiUrl + 'api/user/me').pipe(
      map((user) => {
        this.setUser(user);
        if (!this.refreshInterval) {
          this.refreshInterval = window.setInterval(() => {
            const token = localStorage.getItem('token');
            if (token) {
              this.attemptRefresh(token).subscribe((x) => { });
            } else {
              window.clearInterval(this.refreshInterval);
            }
          }, 1000 * 60 * 10);
        }
      })
    );
  }

  attemptRefresh(token: string): Observable<void> {
    const body = new HttpParams()
      .set('refresh_token', token)
      .set('grant_type', 'refresh_token')
      .set('client_id', 'web.client');

    return this.apiService
      .postAttemptRefresh<any, Token>(environment.apiUrl + 'connect/token', body)
      .pipe(
        map((refreshToken) => {
          this.apiService.setToken(refreshToken);
        })
      );
  }

  isLoggedIn(): boolean {
    const user = JSON.parse(window.sessionStorage.getItem('loggedinUser'));
    return user !== undefined && user !== null;
  }

  isLoggedInUserApproved(): boolean {
    const user = JSON.parse(window.sessionStorage.getItem('loggedinUser'));
    if (user === undefined || user === null) {
      return false;
    } else {
      return user.isApproved;
    }
  }

  private setUser(user: User) {
    this.tdqureUser = user;
    window.sessionStorage.setItem('loggedinUser', JSON.stringify(user));
    this.userSubject.next(user);
  }
}
