import { DataSource } from '@angular/cdk/collections';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { Order } from 'app/shared/model/order';
import { Observable, of as observableOf, merge } from 'rxjs';
import { map } from 'rxjs/operators';

export class OrderTableDataSource extends DataSource<Order> {
  data: Order[] = [];

  constructor(private paginator: MatPaginator, private sort: MatSort) {
    super();
  }

  setOrders(orders: Order[]) {
    this.data = orders;
  }

  connect(): Observable<Order[]> {
    const dataMutations = [
      observableOf(this.data),
      this.paginator.page,
      this.sort.sortChange
    ];

    return merge(...dataMutations).pipe(map(() => this.getPagedData(this.getSortedData([...this.data]))));
  }

  disconnect() { }

  getSortedData(data: Order[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'orderCategoryName': return this.compare(a.orderCategory.name, b.orderCategory.name, isAsc);
        case 'agentMachineName': return this.compare(a.agent.machineName, b.agent.machineName, isAsc);
        default: return 0;
      }
    });
  }

  private getPagedData(data: Order[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  private compare(a: string | number, b: string | number, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
