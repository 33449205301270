<div *ngIf="!isInitialized" style="margin-left: 40%; margin-top: 15%">
  <mat-spinner></mat-spinner>
</div>

<div class="row" *ngIf="this.user && this.isInitialized">
  <div class="col-md-3">
    <h1><u>Devices</u></h1>
    <div *ngIf="user.agents" class="row">
      <div class="col-md-5">
        <label>Select an agent:</label>
      </div>

      <div class="col-md-5" style="margin-bottom: 10px">
        <select name="agentList" class="form-control input sm" style="padding: 3px" [(ngModel)]="selectedMachineName">
          <option *ngFor="let agent of availableAgents(); trackBy: itemName" [ngValue]="agent.machineName">
            {{ agent.machineName }}
          </option>
        </select>
      </div>
    </div>

    <div *ngIf="fetchingDevices" style="margin-left: 20%">
      <mat-spinner></mat-spinner>
    </div>

    <table *ngIf="
        !fetchingDevices &&
        this.deviceService.selectedAgent &&
        this.deviceService.devices.length > 0
      " class="table" style="border-collapse: collapse">
      <thead>
        <tr>
          <th></th>
          <th>
            <b>Address</b>
          </th>
          <th>
            <b>Name</b>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let device of this.deviceService.devices; let i = index">
          <td>
            <div class="custom-control custom-radio">
              <input type="radio" name="devices" (change)="deviceSelected(device)"
                [checked]="device.id === deviceService.selectedDeviceId" />
            </div>
          </td>
          <td>{{ formatDeviceAddress(device) }}</td>
          <td>{{ device.name }}</td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="!fetchingDevices && this.deviceService.devices.length === 0" style="margin-top: 10px">
      <h2>No devices connected</h2>
    </div>
  </div>
  <div *ngIf="this.deviceService.selectedDevice" class="col-md-3">
    <div>
      <div style="width: 70%">
        <h2><u>Tests</u></h2>
      </div>
      <div class="versionDiagnosticTypeContainer">
        <span>
          <b>Diagnostic Type </b>
          <select name="diagnosticType" [(ngModel)]="orderService.orderDiagnosticTypeToUse">
            <option *ngFor="let type of orderService.availableOrderDiagnosticTypes" [ngValue]="type">
              {{ type }}
            </option>
          </select>
        </span>
        <span style="margin-left: 5px">
          <b>Version </b>
          <select name="versionNumber" (change)="onChangeVersion($event.target.value)">
            <option *ngFor="let category of this.orderCategoryVersions; let i = index"
              [ngValue]="this.orderCategoryVersions[i]">
              {{ this.orderCategoryVersions[i] }}
            </option>
          </select>
        </span>
      </div>
    </div>
    <div style="clear: right"></div>
    <div class="accordion" id="accordionPanel">
      <div id="grouped">
        <div class="card-header" id="headingGrouped" data-toggle="collapse" data-target="#collapseGrouped"
          aria-expanded="true" aria-controls="collapseGrouped" style="cursor: pointer; margin-bottom: 5px">
          <h4>Grouped</h4>
        </div>
        <div id="collapseGrouped" class="collapse show" aria-labelledby="headingGrouped" data-parent="#accordionPanel">
          <h2>Basic Support (Application only)</h2>
          <div class="card-body">
            <div class="p-2">
              <div class="row">
                <div class="form-group">
                  <label> EcuFamily </label>
                  <input [(ngModel)]="model['EcuFamily']" [ngModelOptions]="{ standalone: true }" class="form-control"
                    [type]="text" />
                </div>
                <div class="form-group">
                  <label> EcuGeneration </label>
                  <input [(ngModel)]="model['EcuGeneration']" [ngModelOptions]="{ standalone: true }"
                    class="form-control" [type]="text" />
                </div>
              </div>
              <div class="row">
                <button class="btn btn-primary" (click)="submitBasicSupport()">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="individual">
        <div class="card-header" id="headingIndividual" data-toggle="collapse" data-target="#collapseIndividual"
          aria-expanded="false" aria-controls="collapseIndividual" style="cursor: pointer">
          <h4>Individual</h4>
        </div>
        <div id="collapseIndividual" class="collapse" aria-labelledby="headingIndividual" data-parent="#accordionPanel">
          <div class="card-body">
            <div *ngFor="let groupName of this.ordCatGrpKeys">
              <div *ngFor="
                  let orderCategory of getFilteredOrders(
                    groupName,
                    orderService.orderDiagnosticTypeToUse
                  );
                  let i = index
                ">
                <app-order-category class="p-2" [orderCategory]="orderCategory" [user]="user"
                  [selectedVersionNumber]="versionNumberSelected" [selectedDevice]="deviceService.selectedDevice"
                  [selectedAgent]="deviceService.selectedAgent">
                </app-order-category>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="this.deviceService.selectedDevice" class="col-md-3">

    <h1><u>Current session</u></h1>
    <div *ngFor="
        let order of this.orderService.currentSessionAgentOrders(
          this.deviceService.selectedAgent
        )
      ">
      <app-polling-order [order]="order"></app-polling-order>
      <br />
    </div>
    <button *ngIf="this.orderService.currentSessionAgentOrders(this.deviceService.selectedAgent).length>0"
      class="btn btn-primary" (click)="downloadTestReport()">
      Download Test Report
    </button>
  </div>
  <div *ngIf="this.user" class="col-md-3">
    <h1><u>Recent</u></h1>
    <div *ngFor="let order of this.orderService.recentOrders">
      <app-polling-order [order]="order"></app-polling-order>
      <br />
    </div>
  </div>
</div>
<div *ngIf="this.user && !this.user.agents">
  <h2>No Agent connected to this user</h2>
  <h3>Please log in to your Agent</h3>
</div>