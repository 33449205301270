<form class="col-md-6" #changePasswordForm="ngForm">
  <div class="form-group">
    <label for="currentpassword">Current Password</label>
    <input type="password" class="form-control" placeholder="Current Password" id="currentpassword"
      name="currentpassword" [(ngModel)]="changepwdmodel.password" required #currentpassword="ngModel" />
    <div *ngIf="currentpassword.touched && currentpassword.errors?.required" class="alert alert-danger">
      Password is required.
    </div>
  </div>
  <div class="form-group">
    <label for="newpassword">New Password</label>
    <input type="password" class="form-control" placeholder="New Password" id="newpassword" name="newpassword"
      [(ngModel)]="changepwdmodel.newPassword" required maxlength="15" required #newpassword="ngModel"
      pattern="^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%&()?_^+=])(.{8,15})$" />
    <div *ngIf="newpassword.touched && newpassword.errors?.required" class="alert alert-danger">
      Password is required.
    </div>
    <div *ngIf="newpassword.errors?.pattern" class="alert alert-danger">
      Password does not match criteria.
    </div>
    <small class="form-text text-muted">Passwords are required to have a capital letter, number and a special
      character(such as '!' '#' '!' '@' '#' '$' '%' '&' '(' ')' '?' '_' '^' '+'
      ')'</small>
  </div>
  <div class="form-group">
    <label for="confirmPassword">Confirm Password</label>
    <input type="password" class="form-control" placeholder="Confirm Password" id="confirmPassword"
      name="confirmPassword" #modelConfirmPassword="ngModel" [(ngModel)]="confirmPassword" required
      ngValidateEqual="newpassword" />
    <div class="alert alert-danger" *ngIf="modelConfirmPassword.hasError('required') && newpassword.valid">
      Confirm Password is required.
    </div>
    <div class="alert alert-danger" *ngIf="modelConfirmPassword.hasError('notEqual') && newpassword.valid">
      Passwords must match.
    </div>
  </div>

  <button type="submit" class="btn btn-primary" [disabled]="!changePasswordForm.form.valid" (click)="changePassword()">
    Submit
  </button>
</form>