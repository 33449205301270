import { OrderCategory } from './ordercategory';
import { User } from './user';
import { Device } from './device';
import { Command } from './command';
import { Agent } from './agent';

export class Order {
  public id: string;
  public parameters: string;
  public orderCategory: OrderCategory;
  public user: User;
  public device: Device;
  public isPassing: boolean;
  public isComplete: boolean;
  public commands: Command[];
  public agent: Agent;
  public createdAt: string;
}
