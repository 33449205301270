import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService, UserModel } from 'app/services/user-service';
import { MessageService } from 'app/services/message-service.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css'],
})
export class ResetpasswordComponent implements OnInit {
  public userNameExist = true;
  public resetPasswordForm = new UntypedFormGroup({
    email: new UntypedFormControl(),
  });

  constructor(
    private router: Router,
    private userService: UserService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void { }

  checkUserName(email: string) {
    const userModel: UserModel = { email };
    this.userService.checkUserNameExists(userModel).subscribe((data) => {
      this.userNameExist = this.assignValueUserNameExist(data);
    });
  }

  assignValueUserNameExist(data: any) {
    return data === true ? true : false;
  }

  onSubmit() {
    const email = this.resetPasswordForm.value['email'];
    const userModel: UserModel = { email };
    this.userService.checkUserNameExists(userModel).subscribe((data) => {
      this.userNameExist = this.assignValueUserNameExist(data);
      if (this.userNameExist) {
        this.userService.resetPassword(userModel).subscribe((passwordResetSuccess) => {
          if (passwordResetSuccess['isSuccessful'] === true) {
            this.messageService.dispatchSuccessMessage(
              'Password reset done successfully, check email for new password'
            );
          }
        });
      } else {
        this.messageService.dispatchWarningMessage('The entered email was invalid');
      }
    });
  }
}
