<table class="table" style="border-collapse: collapse">
  <thead>
    <tr>
      <th style="display: none"><b>Id</b></th>
      <th>
        <b>Parameters</b>
      </th>
      <th>
        <b>Order Category</b>
      </th>
      <th>
        <b>Email</b>
      </th>
      <th>
        <b>Device Name</b>
      </th>
      <th>
        <b>Passing</b>
      </th>
      <th>
        <b>Complete</b>
      </th>
      <th>
        <b>Order Date</b>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="this.order">
      <td style="display: none">{{ order.id }}</td>
      <td>{{ order.parameters }}</td>
      <td>{{ order.orderCategory.name }}</td>
      <td>{{ order.user.email }}</td>
      <td>{{ order.device.name }}</td>
      <td>{{ order.isPassing }}</td>
      <td>{{ order.isComplete }}</td>
      <td>{{ order.createdAt | date: "yyyy-MM-dd" }}</td>
    </tr>
    <tr *ngIf="!this.order">
      <td colspan="7">Found no Orders</td>
    </tr>
  </tbody>
</table>
<br />

<table *ngIf="this.order" class="table" style="border-collapse: collapse">
  <thead>
    <tr>
      <th style="display: none">
        <b>Id</b>
      </th>
      <th>
        <b>Name</b>
      </th>
      <th>
        <b>Request</b>
      </th>
      <th>
        <b>Passing</b>
      </th>
      <th>
        <b>Message</b>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="this.order.commands.length === 0">
      <td colspan="5">Found no Order commands</td>
    </tr>
    <tr *ngFor="let command of this.order.commands; let i = index">
      <td style="display: none">{{ command.id }}</td>
      <td>{{ command.name }}</td>
      <td>{{ command.requestAsString }}</td>
      <td>{{ command.isPassing }}</td>
      <td (click)="toggleChild()">
        <!-- {{ (command.message.length> 25 )? (command.message | slice:0:25)+'...':(command.message) }} -->

        <!-- {{ (command.message.length> 25 )? (command.message | slice:">>:4")+'...':(command.message) }}
        <img src="../../../../../../font/iconsrc/info_msg.png" alt="" width="16" height="16" data-toggle="tooltip"
          data-placement="top" title="Hooray!">
        <div *ngIf="showVar">
          {{ command.message }}
        </div> -->
        {{ splitString(command.message) }}

        <img
          *ngIf="description && command.message"
          src="../../../../../../font/iconsrc/info_msg.png"
          alt=""
          width="16"
          height="16"
          data-animation="true"
          style="cursor: help"
          data-toggle="tooltip"
          data-placement="top"
          title="{{ description }}"
        />
      </td>
    </tr>
  </tbody>
</table>
