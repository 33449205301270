<table *ngIf="this.order.parameters.length > 2">
    <thead>
        <tr>
            <th>Parameter</th>
            <th>
                <b>Value</b>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let param of this.parameterKeys">
            <td>{{ param }}</td>
            <td>
                <b>{{ this.parameters[param] }}</b>
            </td>
        </tr>
    </tbody>
</table>