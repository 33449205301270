import { Injectable } from '@angular/core';
import { ApiProxyService } from 'app/services/api.service';
import { Agent } from 'app/shared/model/agent';
import { Device } from 'app/shared/model/device';
import { Order } from 'app/shared/model/order';
import { OrderCategory } from 'app/shared/model/ordercategory';
import { User } from 'app/shared/model/user';
import { environment } from 'environments/environment';
import * as FileSaver from 'file-saver';
import _groupBy from 'lodash/groupBy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  orderCategoryGroups: any;
  orderCategoryGroupKeys: string[];
  orderCategories: OrderCategory[] = [];

  availableOrderDiagnosticTypes: string[] = [];
  orderDiagnosticTypeToUse = '';

  pollingOrders: string[] = [];
  displayOrders: Order[] = [];
  recentOrders: Order[] = [];
  allOrders: Order[] = [];
  pollOrderInterval: number;
  orderRefreshInterval: number;


  constructor(
    private apiService: ApiProxyService) { }

  currentSessionAgentOrders(selectedAgent: Agent): Order[] {
    return this.displayOrders.filter(x => x.agent.id === selectedAgent.id);
  }

  fetchOrderCategories(): Observable<void> {
    return this.apiService
      .get<OrderCategory[]>(environment.apiUrl + 'api/Order/categories')
      .pipe(
        map((orderCategories) => {
          this.availableOrderDiagnosticTypes =
            Object.keys(_groupBy(
              orderCategories,
              (order) => order.orderDiagnosticType
            ));
          this.orderDiagnosticTypeToUse = this.availableOrderDiagnosticTypes ? this.availableOrderDiagnosticTypes[0] : '';

          this.orderCategoryGroups = _groupBy(
            orderCategories,
            (ot) => ot.version
          );
          this.orderCategoryGroupKeys = Object.keys(this.orderCategoryGroups);
        })
      );
  }

  fetchPollingOrders(agent: Agent): Observable<void> {
    return this.apiService
      .get<Order[]>(environment.apiUrl + 'api/Order/q?agentId=' + agent.id)
      .pipe(
        map((displayOrders) => {
          displayOrders.forEach((order) => {
            if (this.pollingOrders.includes(order.id)) {
              const index = this.displayOrders.findIndex(
                (o) => o.id === order.id
              );
              if (index >= 0) {
                this.displayOrders.splice(index, 1, order);
              } else {
                this.displayOrders.push(order);
              }
            }
          });
        })
      );
  }

  fetchRecentOrders(user: User): Observable<void> {
    let idsToExclude = [];
    if (this.displayOrders.length > 0) {
      const ids = this.displayOrders.filter((a) => a.id);
      idsToExclude = ids.map((m) => m.id);
    }
    let qryString = 'userId=' + user.id + '&amountLimit=5';
    let strIdValuesToExclude = '';
    if (idsToExclude.length > 0) {
      for (let _i = 0; _i < idsToExclude.length; _i++) {
        if (_i === 0) {
          strIdValuesToExclude = idsToExclude[_i];
        } else {
          strIdValuesToExclude =
            strIdValuesToExclude + '&idsToExclude=' + idsToExclude[_i];
        }
      }
      qryString = qryString + '&idsToExclude=' + strIdValuesToExclude;
    }
    return this.apiService
      .get<Order[]>(environment.apiUrl + 'api/Order/q?' + qryString)
      .pipe(
        map((recentOrders) => {
          this.recentOrders = recentOrders;
        })
      );
  }

  fetchAllOrders(user: User): Observable<void> {
    return this.apiService
      .get<Order[]>(environment.apiUrl + 'api/Order/q?userId=' + user.id)
      .pipe(
        map((allOrders) => {
          this.allOrders = allOrders;
        })
      );
  }

  getOrder(orderId: string): Observable<Order> {
    return this.apiService
      .get<Order>(environment.apiUrl + 'api/Order/' + orderId)
      .pipe(map((order) => order));
  }

  removeFromOrders(order: Order) {
    this.displayOrders.forEach((displayOrder) => {
      if (displayOrder.id === order.id) {
        const index = this.displayOrders.indexOf(displayOrder);
        this.displayOrders.splice(index, 1);
      }
    });
    this.recentOrders.forEach((displayOrder) => {
      if (displayOrder.id === order.id) {
        const index = this.recentOrders.indexOf(displayOrder);
        this.recentOrders.splice(index, 1);
      }
    });
  }

  retrieveLatestAgentVersion(): Observable<string> {
    return this.apiService.getString(environment.apiUrl + 'api/agentversion').pipe(map((agentVersion) => agentVersion));
  }

  newOrder(
    model: any,
    orderCategory: OrderCategory,
    user: User,
    agent: Agent,
    device: Device
  ) {
    const orderPayload: NewOrder = new NewOrder();
    orderPayload.name = orderCategory.name;
    orderPayload.parameters = JSON.stringify(model);
    orderPayload.address = device.address;
    orderPayload.orderCategoryVersion = orderCategory.version;
    orderPayload.userId = user.id;
    orderPayload.agentId = agent.id;

    return this.apiService
      .post<NewOrder, string>(environment.apiUrl + 'api/order', orderPayload)
      .pipe(
        map((orderId) => {
          this.pollingOrders.unshift(orderId);
        })
      );
  }

  deleteOrder(order: Order) {
    return this.apiService
      .delete<Order>(
        environment.apiUrl + 'api/order/deleteOrder?id=' + order.id
      )
      .pipe(map((_) => { }));
  }

  basicSupportOrder(orderPayloadsArr: NewOrder[]) {
    return this.apiService
      .post<NewOrder[], string>(
        environment.apiUrl + 'api/order/addGroupedTests',
        orderPayloadsArr
      )
      .pipe(
        map((data) => {
          for (const orderId of data) {
            this.pollingOrders.unshift(orderId);
          }
        })
      );
  }

  downloadS3(agentVersion: string) {
    const url =
      `https://s3-eu-west-1.amazonaws.com/tdqure.agent/TDQure-Agent-${agentVersion}.zip`;
    return this.apiService.getArrayBuffer<any>(url).pipe(
      map((data) => {
        this.saveFileToLocal(data);
      })
    );
  }

  private saveFileToLocal(data: any) {
    const blob = new Blob([data], {
      type: 'application/zip',
    });
    FileSaver.saveAs(blob, 'TDQure-Agent.zip');
  }
}

export class NewOrder {
  name: string;
  parameters: string;
  address: number;
  orderCategoryVersion: string;
  userId: string;
  agentId: string;
}
