import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { OrderService } from 'app/services/order-service';
import { Order } from 'app/shared/model/order';
import { Utilities } from 'app/shared/utilities';
import { User } from 'app/shared/model/user';

@Component({
  selector: 'app-order-page',
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.css'],
})
export class OrderPageComponent implements OnInit {
  user: User;
  order: Order;
  showVar = false;
  description: string;
  componentInitialized: boolean;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private orderService: OrderService
  ) { }

  getBytes(value: string): any[] {
    const bytes = [];
    for (let i = 0; i < value.length; ++i) {
      const code = value.charCodeAt(i);
      const byte = Utilities.formatByte(code);
      bytes.push([byte]);
    }
    return bytes;
  }

  ngOnInit(): void {
    this.authService.userSubject.subscribe((u) => {
      if (u !== null) {
        this.initializeComponent(u);
      }
    });
    if (this.authService.tdqureUser) {
      this.initializeComponent(this.authService.tdqureUser);
    }
  }

  toggleChild() {
    this.showVar = !this.showVar;
  }

  initializeComponent(user: User) {
    this.user = user;
    if (!this.componentInitialized) {
      this.orderService
        .getOrder(this.route.snapshot.params.orderId)
        .subscribe((order) => {
          this.order = order;
        });

      this.componentInitialized = true;
    }
  }

  splitString(nrcMessage: string) {
    if (nrcMessage === null) {
      return '';
    }
    const msgs = nrcMessage.split('Description:');

    this.description = msgs[1];
    return msgs[0];
  }
}
