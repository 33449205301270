<table *ngIf="this.userService.users" class="table" style="border-collapse: collapse;">
  <thead>
    <tr>
      <th>
        <b>Email</b>
      </th>
      <th>
        <b>Company</b>
      </th>
      <th>
        <b>Approved</b>
      </th>
      <th>
        <b>Admin</b>
      </th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let user of this.userService.users; let i = index">
      <td>{{ user.email }}</td>
      <td>{{ user.company }}</td>
      <td>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="customCheck2_{{ i }}" [(ngModel)]="user.isApproved"
            (change)="changeUserApprovalInfo(user, $event)" />
          <label class="custom-control-label" for="customCheck2_{{ i }}">
          </label>
        </div>
      </td>
      <td>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="customCheck3_{{ i }}" [(ngModel)]="user.isAdmin"
            (change)="changeUserAdminInfo(user, $event)" />
          <label class="custom-control-label" for="customCheck3_{{ i }}">
          </label>
        </div>
      </td>
      <td>
        <button type="button" class="btn btn-danger btn-sm" title="delete"
          (click)="openConfirmationDialog(user)">X</button>
      </td>
    </tr>
  </tbody>
</table>
<div *ngIf="!this.userService.users">
  Found no users
</div>
<button type="button" class="btn btn-primary" (click)="updateUsers()">
  Save
</button>