import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrderCategory } from 'app/shared/model/ordercategory';
import { OrderService } from 'app/services/order-service';
import { OrderInfo } from 'app/shared/orderInfo';
import { User } from 'app/shared/model/user';
import { Device } from 'app/shared/model/device';
import { Agent } from 'app/shared/model/agent';

@Component({
  selector: 'app-order-category',
  templateUrl: './order-category.component.html',
  styleUrls: ['./order-category.component.css'],
})
export class OrderCategoryComponent implements OnInit {
  @Input()
  orderCategory: OrderCategory;
  @Input()
  user: User;
  @Input()
  selectedDevice: Device;
  @Input()
  selectedAgent: Agent;

  parsedParams: string[];
  model: any = new Object();

  constructor(private orderService: OrderService) { }
  ngOnInit(): void {
    const struct = JSON.parse(this.orderCategory.parameterStructure);
    this.parsedParams = Object.keys(struct).filter(
      (key) =>
        key !== 'Responses' && key !== 'Request' && key !== 'DeviceAddress'
    );
    this.parsedParams.forEach((field) => {
      this.model[field] = null;
    });
  }
  submit() {
    const orderInfo: OrderInfo = new OrderInfo();
    orderInfo.model = this.model;
    orderInfo.category = this.orderCategory;
    this.orderService
      .newOrder(
        orderInfo.model,
        orderInfo.category,
        this.user,
        this.selectedAgent,
        this.selectedDevice
      )
      .subscribe((u) => { });
  }
}
