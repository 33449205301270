import { Component, OnInit } from '@angular/core';
import { User } from 'app/shared/model/user';
import { UserService } from 'app/services/user-service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { ApiProxyService } from 'app/services/api.service';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { MessageService } from 'app/services/message-service.service';
import { ConfirmationDialogService } from 'app/services/confirmation-dialog.service';

@Component({
  selector: 'app-user-management-page',
  templateUrl: './user-management-page.component.html',
  styleUrls: ['./user-management-page.component.css'],
})
export class UserManagementPageComponent implements OnInit {

  users: User[] = [];
  usersExistInDb: User[] = [];
  usersToNotifiy: string[] = [];
  constructor(
    public userService: UserService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private apiService: ApiProxyService,
    private messageService: MessageService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
  }

  ngOnInit() {
    this.userService.fetchUsers().subscribe((u) => { });
  }

  deleteUser(user: User) {
    console.log(user);
    this.userService.removeUser(user.id).subscribe({
      next: (_) => {
        this.userService.fetchUsers().subscribe((u) => {
          this.messageService.dispatchSuccessMessage(
            'User deleted successfully'
          );
        });
      },
    });
  }

  updateUsers() {
    this.userService.updateUsers(this.userService.users).subscribe({
      next: (_) => { },
    });
  }

  public openConfirmationDialog(user: User) {
    this.confirmationDialogService
      .confirm(
        'Please confirm',
        'Are you sure you want to delete this user?',
        'Yes',
        'No'
      )
      .then((confirmed) => {
        if (confirmed) {
          this.deleteUser(user);
        }
      })
      .catch(() =>
        console.log(
          'User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'
        )
      );
  }
}
