import { Component, OnInit } from '@angular/core';
import { OrderService } from 'app/services/order-service';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-installation-page',
  templateUrl: './installation-page.component.html',
  styleUrls: ['./installation-page.component.css'],
})
export class InstallationPageComponent implements OnInit {
  downloadURL: string;
  agentVersion: string;

  constructor(private orderService: OrderService, private authService: AuthService) { }

  ngOnInit() {
    if (this.authService.tdqureUser) {
      this.orderService.retrieveLatestAgentVersion().subscribe(agentVersion => {
        this.agentVersion = agentVersion;
        this.downloadURL = `https://s3-eu-west-1.amazonaws.com/tdqure.agent/TDQure-Agent-${agentVersion}.zip`;
      });
    } else {
      this.authService.userSubject.pipe(take(1)).subscribe((user) => {
        this.orderService.retrieveLatestAgentVersion().subscribe(agentVersion => {
          this.agentVersion = agentVersion;
          this.downloadURL = `https://s3-eu-west-1.amazonaws.com/tdqure.agent/TDQure-Agent-${agentVersion}.zip`;
        });
      });
    }
  }

  download() {
    this.orderService.downloadS3(this.agentVersion).subscribe((x) => { });
  }
}
