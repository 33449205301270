<h1>Contact</h1>
Please email jeroen.hoekstra@scania.com with any questions and/or comments.
<h1>Prerequisites</h1>
<b>1.</b> We are currently supporting testing for Harmonized UDS version 2.2 and 2.3 <br />
<b>2.</b> To be able to identify the ECU we require it to answer to a functional set detfault session request (to
address FF) <br />
and for ECUID 0xF197 SystemNameOrEngineTypeDataIdentifier to be implemented.

<h1>Known problems</h1>
<b>1.</b> If an ECU responds with Negative Response code 0x78 (response pending)
your test will fail.<br />
Our communication stack currently does not handle this properly.<br />
<b>2.</b> If you have more than 1 ECU connected you may experience bugs.<br />
The TDQure communication stack was initially developed for only having one ECU
connected <br />
and we have been working on making it possible to support multiple connection,
but it is not guaranteed to work at the moment.<br />
<b>3.</b> If there is another program communicating with your connected ECU's
the Agent will crash. <br />
You can verify this by checking if the 'can' light is off on your VCI
<b>4.</b> We are currently investigating an issue where ECU's connected to CANalyzer are not showing up properly. <br />
This seems to be related to our communication stack, which we are currently making a new implementation of.<br />
For now the best way to test is to use a Kvaser VCI3<br />
<b>5.</b> Some of our tests do not display all the requests we do. This is because these tests require some logic from
the agent <br />
The error is still relevant, but the request it shows might not be the correct one. The tests are still described below,
so it should be <br />
easy to reproduce the error. Affected tests: DTC/Fingerprint/Security Access

<h1>Our tests</h1>
In order to be as transparant as possible we have written out exactly what we
test. <br />
You will see a header with the test name and then a description of what we
request from the ECU and what we expect it to answer.

<h4>ECUID</h4>
For the ECUID test we request all the DID's marked as mandatory in the selected
version of the standard. <br />
We then verify every single one as they are specified in the 'Data identifier
details' section of the standard. <br />
For this test we also take the ECUFamily and ECUGeneration as parameters, to
verify that the response to F197(SystemNameOrEngineTypeDataIdentifier) will be
compliant with SCOMM.

<h4>ECUReset</h4>
For the ECUReset test we request 0x11 0x01 (Hard Reset) from the ECU and we
verify that we get a positive response.

<h4>Fingerprint</h4>
For the Fingerprint test we start off with making sure we can't write a fingerprint from default session,
after that we read the fingerprint (0x22 0xF1 0x70) and verify it like in the ECUID test. <br />
After this we unlock the security access (see SecurityAccess) and we attempt to
write a fingerprint with default data (0x2E 0xF1 0x70 0xFingerprintData) and we verify that
we get a positive response.

<h4>FunctionalAddressing</h4>
We verify that the ECU responds to all Tester Addresses when making functional
calls (we use 0x10 0x01 as our request). <br />
We check that the ECU has responded 255 times (range of 0x00-0xFE).

<h4>NormalMessageTransmission</h4>
we first verify that we are unable to set the message transmission from default session, after that we follow the
following sequence:
<br />
<b>1.</b> Request 0x10 0x03 (Set extended diagnostic session), verify that we
get a positive response. <br />
<b>2.</b> Request 0x28 0x01 0x01 physically (Disable normal message
transmission), verify that we did not get a negative response. <br />
<b>3.</b> Request 0x28 0x00 0x01 physically (Enable normal message
transmission), verify that we did not get a negative response. <br />
<b>4.</b> Request 0x28 0x01 0x01 functionally (Disable normal message
transmission), verify that we did not get a negative response .<br />
<b>5.</b> Request 0x28 0x00 0x01 functionally (Enable normal message
transmission), verify that we did not get a negative response. <br />

<h4>PhysicalAddressing</h4>
We verify that the ECU responds to all Tester Addresses when making physical
calls (we use 0x10 0x01 as our request). <br />
We check that the ECU has responded 254 times (range of 0x00-0xFE, apart from
its own address).

<h4>Priority</h4>
For the priority test we send out the 0x10 0x01 (Set default session) request
twice, once with priority 6 and once with priority 7. <br />
We verify that the ECU answer both these request with a positive response and
priority 7.

<h4>SecurityAccess</h4>
The security access test requires you to fill in the shared secret on the Agent,
for security reasons. <br />
This test currently doesn't show all the requests properly in the UI <br />
The security access test follows the following sequence:<br />
<b>1.</b> Request 0x10 0x03, verify positive response.<br />
<b>2.</b> 0x27 0x01 (request seed), verify positive response.<br />
<b>3.</b> 0x27 0x02 0xHashedKey (send key), verify positive response.

<h4>Session</h4>
The session test follows the following sequence:<br />
<b>1.</b> 0x10 0x01 (set default session), verify positive response.<br />
<b>2.</b> 0x10 0x03 (set extended diagnostic session), verify positive
response.<br />
<b>3.</b> 0x31 0x01 0x22 0x03 (check programming preconditions), verify positive
response.<br />
<b>4.</b> 0x85 0x02 (control DTC setting), verify positive response.<br />
<b>5.</b> 0x28 0x01 0x01 (disable normal message transmission), verify positive
response.<br />
<b>6.</b> 0x10 0x02 (set programming session), verify positive response.

<h4>TesterPresent</h4>
For tester present we test the following things: Send tester present
functionally for both KWP and UDS (0x3E 0x01 and 0x3E 0x00). For the KWP tester
present we verify that we do not get a positive response.<br />
For the UDS tester present we test that we get a positive response. <br />
Furthermore we verify that we do not get a positive response when requesting
0x3E 0x02 and 0x3E 0x80.

<h4>DTC</h4>
For our DTC test we first request 0x19 0x02 0x08 to read all DTC's.<br />
After that we request every DTC with 0x19 0x06 0xDTCID 0x11 and verify the
response with the standard. <br />
We also request the reportDTCSnapshotIdentification (0x19 0x03) and verify all DTC's <br />
from that with 0x19 0x04 0xDTCID (verify with table in the standard). <br />
This test currently doesn't show all the requests properly in the UI.

<h4>ClearDiagnosticInformation</h4>
We try to clear all diagnostic information with the request 0x14 0xFF 0xFF 0xFF, <br />
we do both a functional and physical request and verify that we get positive responses.