import { Component, OnInit, Input } from '@angular/core';
import { Order } from 'app/shared/model/order';
import { OrderService } from 'app/services/order-service';
import { User } from 'app/shared/model/user';
import { Subscription } from 'rxjs';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-polling-order',
  templateUrl: './polling-order.component.html',
  styleUrls: ['./polling-order.component.css'],
})
export class PollingOrderComponent implements OnInit {
  @Input()
  order: Order;
  parameters: any = new Object();
  parameterKeys: string[] = [];
  user: User;
  userSubscription: Subscription;

  constructor(private orderService: OrderService, private authService: AuthService,) { }

  ngOnInit(): void {
    this.parameters = JSON.parse(this.order.parameters);
    this.parameterKeys = Object.keys(this.parameters);
    if (this.authService.tdqureUser) {
      this.initializeComponent(this.authService.tdqureUser);
    } else {
      this.authService.userSubject.pipe(take(1)).subscribe((user) => {
        this.initializeComponent(user);
      });
    }
  }

  initializeComponent(user: User) {
    this.user = user;
    this.userSubscription = this.authService.userSubject.subscribe((newUser) => {
      this.user = newUser;
    });
  }

  deleteOrder() {
    this.orderService.deleteOrder(this.order).subscribe((u) => {
      this.orderService.removeFromOrders(this.order);
      this.orderService.fetchRecentOrders(this.user).subscribe((o) => { });
    });
  }
}
