import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InstallationPageComponent } from './pages/installation-page/installation-page.component';
import { CommonModule, DatePipe } from '@angular/common';

import { NavbarComponent } from './navigation/navbar/navbar.component';
import { RunTestsPageComponent } from './pages/run-tests-page/run-tests-page.component';
import { RegistrationPageComponent } from './pages/registration-page/registration-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { UserManagementPageComponent } from './pages/user-management-page/user-management-page.component';
import { OrderCategoryComponent } from './shared/components/order-category/order-category.component';
import { PollingOrderComponent } from './shared/components/polling-order/polling-order.component';
import { OrderPageComponent } from './pages/order-page/order-page.component';
import { MessageTemplateComponent } from './modules/shared/message-handler/message-templates.component';
import { MyOrdersPageComponent } from './pages/my-orders-page/my-orders-page.component';
import { ConfirmationDialogComponent } from './shared/components/confirmation-dialog/confirmation-dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { ValidateEqualModule } from 'ng-validate-equal';
import { ChangepasswordComponent } from './pages/changepassword/changepassword.component';
import { InformationPageComponent } from './pages/information-page/information-page.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { AppPollingMyOrderParametersComponent } from './shared/components/app-polling-my-order-parameters/app-polling-my-order-parameters.component';

@NgModule({
    declarations: [
        AppComponent,
        InstallationPageComponent,
        NavbarComponent,
        RunTestsPageComponent,
        RegistrationPageComponent,
        HomePageComponent,
        LoginPageComponent,
        UserManagementPageComponent,
        OrderCategoryComponent,
        PollingOrderComponent,
        OrderPageComponent,
        MessageTemplateComponent,
        MyOrdersPageComponent,
        ConfirmationDialogComponent,
        ResetpasswordComponent,
        ChangepasswordComponent,
        InformationPageComponent,
        AppPollingMyOrderParametersComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatIconModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatButtonModule,
        CommonModule,
        NgbModule,
        ValidateEqualModule,
        MatProgressSpinnerModule
    ],
    providers: [DatePipe],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
