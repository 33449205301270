<div *ngIf="parsedParams.length > 0">
  <h2>{{ orderCategory.name }}</h2>
</div>
<form>
  <div *ngIf="parsedParams.length > 0">
    <div class="form-group" *ngFor="let param of parsedParams">
      <label>
        {{ param }}
      </label>
      <input
        [(ngModel)]="model[param]"
        [ngModelOptions]="{ standalone: true }"
        class="form-control"
        [type]="param.toLowerCase().includes('secret') ? 'password' : 'text'"
      />
    </div>
    <div *ngIf="parsedParams.length > 0">
      <button class="btn btn-primary" (click)="submit()">Submit</button>
    </div>
  </div>

  <div *ngIf="parsedParams.length === 0">
    <div class="row">
      <div class="col-md-6">
        <h2>{{ orderCategory.name }}</h2>
      </div>
      <div class="col-md-6 text-right">
        <button class="btn btn-primary" (click)="submit()">Submit</button>
      </div>
    </div>
  </div>
</form>
