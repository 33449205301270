import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { DeviceService } from 'app/services/device-service';
import { NewOrder, OrderService } from 'app/services/order-service';
import { Agent } from 'app/shared/model/agent';
import { Device } from 'app/shared/model/device';
import { OrderCategory } from 'app/shared/model/ordercategory';
import { User } from 'app/shared/model/user';
import { Utilities } from 'app/shared/utilities';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { formatDate, DatePipe } from '@angular/common';



@Component({
  selector: 'app-run-tests',
  templateUrl: './run-tests-page.component.html',
  styleUrls: ['./run-tests-page.component.css'],
})
export class RunTestsPageComponent implements OnInit, OnDestroy {
  user: User;
  fakeDeviceId = 1;
  componentInitialized: boolean;
  fetchingDevices: boolean;
  model: any = new Object();
  fetchDeviceSubscription: Subscription;
  userSubscription: Subscription;
  fetchPollingOrdersSubscription: Subscription;
  agents: Agent[];
  isInitialized: boolean;
  orderCategoryVersions: any[] = [];
  ordCatGrpKeys: string[] = [];
  private _selectedMachineName: string;
  public get selectedMachineName(): string {
    return this._selectedMachineName;
  }
  public set selectedMachineName(agentName: string) {
    this._selectedMachineName = agentName;

    if (agentName) {
      const agent = this.user.agents.find(a => a.machineName === this.selectedMachineName);
      this.deviceService.selectedAgent = agent;
      this.fecthDevicesOfSelectedAgent();
    }
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private deviceService: DeviceService,
    private orderService: OrderService,
    public datepipe: DatePipe
  ) { }

  deviceSelected(device: Device) {
    this.deviceService.selectedDevice = device;
    this.deviceService.selectedDeviceId = device.id;
  }

  formatDeviceAddress(device: Device): string {
    return Utilities.formatByte(device.address);
  }

  ngOnInit() {
    if (this.authService.tdqureUser) {
      this.initializeComponent(this.authService.tdqureUser);
    } else {
      this.authService.userSubject.pipe(take(1)).subscribe((user) => {
        this.initializeComponent(user);
      });
    }
  }

  onChangeVersion(value: string) {
    this.ordCatGrpKeys = this.orderService.orderCategoryGroupKeys.filter(ver => ver === value);
  }

  initializeComponent(user: User) {
    this.user = user;

    this.userSubscription = this.authService.userSubject.subscribe((newUser) => {
      this.user = newUser;
    });
    const wpfAgents = this.availableAgents();
    if (wpfAgents.length > 0) {
      this.selectedMachineName = this.selectedMachineName ? this.selectedMachineName : wpfAgents[0].machineName;
      this.orderService.fetchOrderCategories().subscribe((u) => {
        this.orderCategoryVersions = this.orderService.orderCategoryGroupKeys;
        this.orderCategoryVersions.sort((a, b) => b - a);

        this.ordCatGrpKeys = this.orderService.orderCategoryGroupKeys.filter(ver => ver === this.orderCategoryVersions[0]);
      });
      this.orderService.fetchRecentOrders(this.user).subscribe((o) => { });
      this.componentInitialized = true;
    }

    this.deviceService.initializeDevicePolling();
    this.isInitialized = true;
  }

  submitBasicSupport() {
    const orderPayloadsArr: NewOrder[] = [];
    this.ordCatGrpKeys = this.orderService.orderCategoryGroupKeys;
    this.orderService.orderCategoryGroupKeys.forEach((groupName) => {
      for (const orderCategory of this.orderService.orderCategoryGroups[
        groupName
      ]) {
        const orderPayload: NewOrder = new NewOrder();
        orderPayload.name = orderCategory.name;
        if (orderCategory.name === 'ECUID') {
          orderPayload.parameters = JSON.stringify(this.model);
        } else {
          orderPayload.parameters = JSON.stringify(new Object());
        }

        orderPayload.address = this.deviceService.selectedDevice.address;
        orderPayload.orderCategoryVersion = orderCategory.version;
        orderPayload.userId = this.user.id;
        orderPayload.agentId = this.deviceService.selectedAgent.id;
        orderPayloadsArr.push(orderPayload);
      }
    });
    this.orderService.basicSupportOrder(orderPayloadsArr).subscribe((u) => { });
  }

  itemName(index: number, item: Agent) {
    return item.machineName;
  }

  ngOnDestroy(): void {
    if (this.fetchDeviceSubscription) {
      this.fetchDeviceSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.fetchPollingOrdersSubscription) {
      this.fetchPollingOrdersSubscription.unsubscribe();
    }
  }

  availableAgents() {
    if (this.user.agents) {
      if (!this.selectedMachineName && this.user.agents.length > 0) {
        this.selectedMachineName = this.user.agents[0].machineName;
      }
      return this.user.agents.filter(agent => agent.type === 0);
    }
  }

  getFilteredOrders(groupName: string, orderDiagnosticType: string): OrderCategory[] {
    const ordersByGroupName = this.orderService.orderCategoryGroups[
      groupName
    ] as OrderCategory[];

    return ordersByGroupName.filter(order => order.orderDiagnosticType === orderDiagnosticType);
  }

  downloadTestReport() {
    let report = '';
    const table = '<table  style="border:1px solid black;width:100%;color:black;border-collapse:collapse">';
    const tableHeaderRow = '<tr style="border:1px solid black;background-color:#033a6a;color:white;height:30px">';
    const tableHeader = '<th>Order Category</th><th>Device Name</th><th>Name</th><th>Request</th><th> Paasing </th> <th>Message</th></tr>';
    const fileDataFromOrder = [];
    this.orderService.currentSessionAgentOrders(
      this.deviceService.selectedAgent
    ).forEach(
      commands => {
        fileDataFromOrder.push(commands.commands);
      }
    );
    fileDataFromOrder.forEach((e, idx, array) => {
      console.log(e);
      e.forEach(e1 => {
        if (e1.order.isComplete) {
          const orderCategory = e1.order.orderCategory.name;
          const name = e1.name;
          const request = e1.requestAsString;
          const result = String(e1.isPassing);
          let message = e1.message;
          const deviceName = e1.order.device.name;
          if (message === null) {
            message = '';
          }
          report += '<tr style="border:1px solid black;text-align:center"><td style="border:1px solid black;width:15%">' + orderCategory + '</td><td style="border:1px solid black;width:15%">'
            + deviceName + '</td><td style="border:1px solid black;width:25%">' + name + '</td><td style="border:1px solid black;width:15%">'
            + request + '</td><td td style="border:1px solid black;width:10%">' + result + '</td><td td style="border:1px solid black;width:20%">' + message + '</td></tr>';
        }
      });
      if (!(idx === array.length - 1)) {
        report += '<tr style= "height:20px !important;background-color: #FFFFFF;"><td colspan="6"></td></tr>' + table + tableHeaderRow + tableHeader;
      }
    });
    const completeReport = table + tableHeaderRow + tableHeader + report + '</table>';
    FileSaver.saveAs(new Blob([completeReport], { type: 'text/xml;charset=iso-8859-1' }), 'report.html');
  }


  // downloadTestReport() {
  //   let deviceName;
  //   let report = '';
  //   const table = '<table  style="border:1px solid black;width:80%;color:black;border-collapse:collapse;margin-left:auto;margin-right:auto;margin-bottom:10px;">';
  //   const tableHeaderRow = '<tr style="border:1px solid black;background-color:#033a6a;color:white;height:30px">';
  //   //const tableHeader = '<th>Order Category</th><th>Device Name</th><th>Name</th><th>Request</th><th> Paasing </th> <th>Message</th></tr>';
  //   const tableHeader = '<th>Item</th><th>Description</th></tr>';
  //   const fileDataFromOrder = [];
  //   this.orderService.currentSessionAgentOrders(
  //     this.deviceService.selectedAgent
  //   ).forEach(
  //     commands => {
  //       fileDataFromOrder.push(commands.commands);
  //     }
  //   );
  //   fileDataFromOrder.forEach((e, idx, array) => {
  //     e.forEach(e1 => {
  //       if (e1.order.isComplete) {
  //         const orderCategory = e1.order.orderCategory.name;
  //         const name = e1.name;
  //         const request = e1.requestAsString;
  //         // const result = String(e1.isPassing);
  //         const result = e1.isPassing === true ? 'Passed' : 'Failed';
  //         let message = e1.message;
  //         //console.log(e1.order);
  //         deviceName = e1.order.device.name;
  //         if (message === null) {
  //           message = '';
  //         }
  //         report += '<tr style="border:1px solid black;text-align:center">'
  //           + '<td style="border:1px solid black width:25%;"> Order Category  </td><td style="border:1px solid black; width:75%;"> Order Category = '
  //           + orderCategory + ' ,  Dignostic Type = ' + e1.order.orderCategory.orderDiagnosticType + ',  Version = ' + e1.order.orderCategory.version + ' </td>'
  //           + '</tr>'
  //           + '<tr style="border:1px solid black;text-align:center"><td style="border:1px solid black; width:25%;"> Device Name  </td><td style="border:1px solid black; width:75%;">'
  //           + deviceName + '</td>'
  //           + '</tr>'
  //           + '<tr style="border:1px solid black;text-align:center"><td style="border:1px solid black; width:25%;">  Test Name  </td><td style="border:1px solid black; width:75%;">'
  //           + name + '</td>'
  //           + '</tr>'
  //           + '<tr style="border:1px solid black;text-align:center"><td style="border:1px solid black; width:25%;">  Request  </td><td style="border:1px solid black; width:75%;">'
  //           + request + '</td>'
  //           + '</tr>'
  //           + '<tr style="border:1px solid black;text-align:center"><td style="border:1px solid black; width:25%;">  Test Result  </td><td style="border:1px solid black; width:75%;">'
  //           + result + '</td>'
  //           + '</tr>'
  //           + '<tr style="border:1px solid black;text-align:center"><td style="border:1px solid black; width:25%;">  Message  </td><td style="border:1px solid black; width:75%;">'
  //           + message + '</td>'
  //           + '</tr>'
  //           + '<tr style="border:1px solid black;text-align:center"><td style="border:1px solid black; width:25%;">  Test Date  </td><td style="border:1px solid black; width:75%;">'
  //           + this.datepipe.transform(e1.order.createdAt, 'yyyy-MM-dd HH:mm') + '</td>'
  //           + '</tr>'
  //           + '<tr style="border:1px solid black;text-align:center"><td style="border:1px solid black; width:25%;">   User Email    </td><td style="border:1px solid black; width:75%;">'
  //           + e1.order.user.email + '</td>'
  //           + '</tr>'
  //           ;

  //         ;
  //       }
  //     });
  //     if (!(idx === array.length - 1)) {
  //       report += table + tableHeaderRow + tableHeader;
  //     }
  //   });
  //   const currentDate = new Date();
  //   const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
  //   const fileName = deviceName + '-' + cValue;
  //   const completeReport = table + tableHeaderRow + tableHeader + report + '</table>';
  //   FileSaver.saveAs(new Blob([completeReport], { type: 'text/xml;charset=iso-8859-1' }), fileName + '.html');
  // }

  private fecthDevicesOfSelectedAgent() {
    this.fetchingDevices = true;
    this.fetchDeviceSubscription = this.deviceService.fetchDevices(this.deviceService.selectedAgent).subscribe((u) => {
      if (this.deviceService.devices.length) {
        this.deviceSelected(this.deviceService.devices[0]);
      }
      this.fetchingDevices = false;
    });
    if (this.orderService.pollingOrders.length === 0) {
      return;
    }
    this.fetchPollingOrdersSubscription = this.orderService
      .fetchPollingOrders(this.deviceService.selectedAgent)
      .subscribe((o) => { });
  }
}
