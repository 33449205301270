<c-header site-name="TDQure" short-name="TDQURE" site-url="/"> </c-header>

<c-navigation>
  <div *ngIf="isUserLoggedIn" class="dropdown" slot="primary-items">
    <a href="" class="dropdown-toggle nav-item" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <mat-icon svgIcon="person-fill" style="width: 18px; height: 18px; display: inline-block;"></mat-icon>
      {{ user.email }}
    </a>
    <div class="dropdown-menu">
      <a href="" routerLink="/changepassword" class="dropdown-item">Change Password</a>
      <a href="" (click)="logout()" class="dropdown-item">Logout</a>
    </div>
  </div>

  <a *ngIf="!isUserLoggedIn" routerLink="/login" slot="primary-items" routerLinkActive="active">Login</a>
  <a *ngIf="isUserLoggedIn && user.isApproved" routerLink="/installation" slot="primary-items"
    routerLinkActive="active">Installation</a>
  <a *ngIf="isUserLoggedIn && user.isApproved" [routerLink]="['/run']" slot="primary-items"
    routerLinkActive="active">Run tests</a>
  <a *ngIf="isUserLoggedIn && user.isApproved" routerLink="/my-orders" slot="primary-items" routerLinkActive="active">My
    Orders</a>
  <a *ngIf="isUserLoggedIn && user.isApproved" routerLink="/info" slot="primary-items"
    routerLinkActive="active">Information</a>
  <a *ngIf="isUserLoggedIn && user.isAdmin" [routerLink]="['/users']" slot="primary-items"
    routerLinkActive="active">Manage Users</a>
  <a *ngIf="!isUserLoggedIn" [routerLink]="['/register']" slot="primary-items" routerLinkActive="active">Register</a>
</c-navigation>