import { Component } from '@angular/core';
import { MessageTypeEnum } from './messageTypeEnum';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-message-template-component',
  templateUrl: 'message-template-component.html',
  styles: ['img {width: 25px;}', 'b {color: white;}', 'a {color: white;}'],
})
export class MessageTemplateComponent {
  messageType: MessageTypeEnum;
  label: string;
  message: string;
  details: string;
  dismissable: boolean;
  showDetailsExpanded: boolean;
  dismiss: Subject<any> = new Subject();

  messageTypeEnum = MessageTypeEnum;

  toggleDetails() {
    this.showDetailsExpanded = !this.showDetailsExpanded;
  }

  onDismiss() {
    this.dismiss.next();
  }
}
