import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { MessageService } from 'app/services/message-service.service';
import { ChangePasswordModel, UserService } from 'app/services/user-service';
import { User } from 'app/shared/model/user';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css'],
})
export class ChangepasswordComponent implements OnInit {
  changepwdmodel: ChangePasswordModel = new ChangePasswordModel();
  confirmPassword = '';
  currentUser: User;

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.authService.userSubject.pipe(take(1)).subscribe((user) => {
      this.initializeComponent(user);
    });
  }

  initializeComponent(user: User) {
    this.currentUser = user;
  }

  changePassword() {
    this.changepwdmodel.id = this.currentUser.id;
    this.userService.changePassword(this.changepwdmodel).subscribe((data) => {
      if (data['isSuccessful'] === true) {
        this.messageService.dispatchSuccessMessage(
          'Password changed successfully, login again with new password'
        );
        localStorage.removeItem('token');
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        this.router.navigate(['login']);
      }
    });
  }
}
