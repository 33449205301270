<form class="col-md-6" #registerForm="ngForm">
  <div class="form-group">
    <label for="exampleInputEmail1">Email address</label>
    <input type="email" class="form-control" placeholder="Enter email" id="email" name="email" [(ngModel)]="user.email"
      required #email="ngModel" pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" aria-describedby="emailHelp"
      (blur)="checkUserName($event.target.value)" />
    <div class="alert alert-danger" *ngIf="email.touched && userNameExist">
      User already registered
    </div>
    <div *ngIf="
        (email.touched && email?.errors?.required) || email?.errors?.pattern
      " class="alert alert-danger">
      Invalid Email, Please enter valid email address.
    </div>
  </div>
  <div class="form-group">
    <label for="exampleInputPassword1">Password</label>
    <input type="password" class="form-control" placeholder="Password" id="password" name="password"
      [(ngModel)]="user.password" required maxlength="128" #password="ngModel"
      pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-!@#$%&()?_^+=])(.{8,128})$" />
    <div *ngIf="password.errors?.pattern" class="alert alert-danger">
      Password does not match criteria.
    </div>
    <small class="form-text text-muted">Passwords are required to have at least 8 characters, a capital letter,
      number and a special character(such as '!' '#' '!' '@' '#' '$' '%' '&' '('
      ')' '?' '_' '-' '^' '+' ')'</small>
  </div>
  <div class="form-group">
    <label for="exampleInputPassword1">Confirm Password</label>
    <input type="password" class="form-control" placeholder="Confirm Password" id="confirmPassword"
      name="confirmPassword" #modelConfirmPassword="ngModel" [(ngModel)]="confirmPassword" required
      ngValidateEqual="password" />
    <div class="alert alert-danger" *ngIf="modelConfirmPassword.hasError('required') && password.valid">
      Confirm Password is required.
    </div>
    <div class="alert alert-danger" *ngIf="modelConfirmPassword.hasError('notEqual') && password.valid">
      Passwords must match.
    </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail1">Company</label>
    <input type="text" class="form-control" id="company" aria-describedby="companyHelp" placeholder="Enter company Name"
      [(ngModel)]="user.company" name="company" #company="ngModel" required />
    <div class="alert alert-danger" *ngIf="company.touched && company.hasError('required')">
      Company Name is required.
    </div>
  </div>
  <button type="submit" class="btn btn-primary" [disabled]="!registerForm.form.valid" (click)="register()">
    Submit
  </button>
</form>