import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MessageService } from './message-service.service';
import { ApiMessageService } from './api-message.service';
import { ApiErrorDetails } from 'app/modules/shared/api/api-error-details';
import { Token } from 'app/modules/authentication/shared/token';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ApiProxyService {
  token: Token = null;
  requestOptions: any = {
    headers: this.createHeadersNoAuth(),
    responseType: 'arraybuffer',
  };

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private apiMessageService: ApiMessageService,
    private router: Router
  ) { }

  get<T>(url: string): Observable<T> {
    const apiMessage = this.apiMessageService.sendGetMessage();
    return this.http
      .get<T>(url, { headers: this.createHeaders() })
      .pipe(
        tap(() => {
          apiMessage.sendCompleted();
        })
      )
      .pipe(
        catchError((e: any) => {
          console.log(e.status);
          const errorMessage = this.errorHandler(e);
          apiMessage.sendError(errorMessage);
          if (!environment.production) {
            //this.messageService.dispatchErrorMessageFromApi(errorMessage);
          }
          return throwError(errorMessage);
        })
      );
  }

  getString(url: string): Observable<string> {
    const apiMessage = this.apiMessageService.sendGetMessage();
    return this.http
      .get(url, {
        headers: this.createHeaders(),
        responseType: 'text'
      })
      .pipe(
        tap(() => {
          apiMessage.sendCompleted();
        })
      )
      .pipe(
        catchError((e: any) => {
          console.log(e.status);
          const errorMessage = this.errorHandler(e);
          apiMessage.sendError(errorMessage);
          if (!environment.production) {
            //this.messageService.dispatchErrorMessageFromApi(errorMessage);
          }
          return throwError(errorMessage);
        })
      );
  }

  getArrayBuffer<T>(url: string): Observable<T> {
    const apiMessage = this.apiMessageService.sendGetMessage();
    return this.http
      .get<T>(url, {
        headers: this.createHeadersNoAuth(),
        responseType: 'arraybuffer' as 'json',
      })
      .pipe(
        tap(() => {
          apiMessage.sendCompleted();
        })
      )
      .pipe(
        catchError((e: any) => {
          console.log(e.status);
          const errorMessage = this.errorHandler(e);
          apiMessage.sendError(errorMessage);
          if (!environment.production) {
            //this.messageService.dispatchErrorMessageFromApi(errorMessage);
          }
          return throwError(errorMessage);
        })
      );
  }

  put<T, Z = T>(url: string, data: T): Observable<Z> {
    const apiMessage = this.apiMessageService.sendPutMessage();
    return this.http
      .put<Z>(url, data, { headers: this.createHeaders() })
      .pipe(
        tap(() => {
          apiMessage.sendCompleted();
        }),
        catchError((e: any) => {
          const errorMessage = this.errorHandler(e);
          apiMessage.sendError(errorMessage);
          // if (!environment.production) {
          this.messageService.dispatchErrorMessageFromApi(errorMessage);
          // }
          return throwError(errorMessage);
        })
      );
  }

  post<T, Y = T>(url: string, data: T): Observable<Y> {
    const apiMessage = this.apiMessageService.sendPostMessage();
    return this.http
      .post<Y>(url, data, { headers: this.createHeaders() })
      .pipe(
        tap(() => {
          apiMessage.sendCompleted();
        }),
        catchError((e: any) => {
          const errorMessage = this.errorHandler(e);
          apiMessage.sendError(errorMessage);
          // if (!environment.production) {
          this.messageService.dispatchErrorMessageFromApi(errorMessage);
          // }
          return throwError(errorMessage);
        })
      );
  }

  postLogin<T, Y = T>(url: string, data: T): Observable<Y> {
    const apiMessage = this.apiMessageService.sendPostMessage();
    return this.http
      .post<Y>(url, data, { headers: this.createLoginHeaders() })
      .pipe(
        tap(() => {
          apiMessage.sendCompleted();
        }),
        catchError((e: any) => {
          const errorMessage = this.errorHandler(e);
          apiMessage.sendError(errorMessage);
          // if (!environment.production) {
          this.messageService.dispatchErrorMessageFromApi(errorMessage);
          // }
          return throwError(errorMessage);
        })
      );
  }

  postAttemptRefresh<T, Y = T>(url: string, data: T): Observable<Y> {
    const apiMessage = this.apiMessageService.sendPostMessage();
    return this.http
      .post<Y>(url, data, { headers: this.createRefreshHeaders() })
      .pipe(
        tap(() => {
          apiMessage.sendCompleted();
        }),
        catchError((e: any) => {
          localStorage.removeItem('token');
          sessionStorage.removeItem('loggedinUser');
          const errorMessage = this.errorHandler(e);
          return throwError(errorMessage);
        })
      );
  }

  delete<T>(url: string): Observable<T> {
    const apiMessage = this.apiMessageService.sendDeleteMessage();
    return this.http
      .delete<T>(url, { headers: this.createHeaders() })
      .pipe(
        tap(() => {
          apiMessage.sendCompleted();
        }),
        catchError((e: any) => {
          const errorMessage = this.errorHandler(e);
          apiMessage.sendError(errorMessage);
          if (!environment.production) {
            this.messageService.dispatchErrorMessageFromApi(errorMessage);
          }
          return throwError(errorMessage);
        })
      );
  }

  setToken(token: Token) {
    this.token = token;
    localStorage.setItem('token', token.refresh_token);
  }

  private createHeaders() {
    if (this.token) {
      const headers = new HttpHeaders({
        authorization: `Bearer ${this.token.access_token}`,
      });
      return headers;
    }
    return null;
  }

  private createRefreshHeaders() {
    if (this.token) {
      const headers = new HttpHeaders({
        authorization: `Bearer ${this.token.access_token}`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/x-www-form-urlencoded'
      });
      return headers;
    } else {
      const headers = new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      return headers;
    }
  }

  private createLoginHeaders() {
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return headers;
  }

  private createHeadersNoAuth() {
    if (this.token) {
      const headers = new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Access-Control-Allow-Origin': '*',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Access-Control-Allow-Headers':
          'Origin, X-Requested-With, Content-Type, Accept',
      });
      return headers;
    }
    return null;
  }

  private clearToken() {
    this.token = null;
  }

  private errorHandler(error: any) {
    const httpErrorResponse = error as HttpErrorResponse;
    const apiErrorDetails = new ApiErrorDetails();
    apiErrorDetails.userMessage =
      'An error occurred. Try again, otherwise contact your local helpdesk.';

    if (httpErrorResponse) {
      apiErrorDetails.statusCode = httpErrorResponse.status;
      apiErrorDetails.statusText = httpErrorResponse.statusText;
      apiErrorDetails.detailedMessage = httpErrorResponse.error
        ? httpErrorResponse.error.detailedMessage
        : '';

      const jsonObject = this.getJsonObject(httpErrorResponse.error);

      if (jsonObject instanceof Error) {
        apiErrorDetails.detailedMessage = httpErrorResponse.error.message;
      } else if (jsonObject.error_description && jsonObject.error_description === 'invalid_username_or_password') {
        apiErrorDetails.userMessage = 'Invalid username or password';
      } else if (jsonObject instanceof ProgressEvent) {
        apiErrorDetails.detailedMessage =
          'An error occurred while communicating with the server. Make sure your network connection is functioning properly.';
      } else if (jsonObject && jsonObject.userMessage) {
        apiErrorDetails.detailedMessage = jsonObject.detailedMessage;
        apiErrorDetails.userMessage = jsonObject.userMessage;
      } else if (jsonObject && jsonObject.errorType === 2) {
        apiErrorDetails.detailedMessage = jsonObject.detailedMessage;
        apiErrorDetails.userMessage = 'Api validation error';
      } else if (jsonObject && jsonObject.errorType === 7) {
        apiErrorDetails.detailedMessage = jsonObject.detailedMessage;
        apiErrorDetails.userMessage = 'Resource authorization failed';
      } else if (typeof httpErrorResponse.error === 'string') {
        apiErrorDetails.detailedMessage = httpErrorResponse.error;
      }

      // if (apiErrorDetails.statusCode === 401) {
      //   localStorage.removeItem("token");
      //   this.router.navigate(["/login"]);
      // }
    }

    return apiErrorDetails;
  }

  private getJsonObject(str) {
    try {
      if (typeof str === 'object') {
        return str;
      }

      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  }
}
