<div style="overflow-wrap: break-word; background-color: #363636; padding: 10px;">
  <b *ngIf="dismissable" class="close" style="cursor: pointer;" (click)="onDismiss()">
    <span aria-hidden="true">X</span>
  </b>
  <table>
    <tr>
      <td>
        <div [ngSwitch]="messageType">
          <img src="../../../../../../font/iconsrc/warning_msg.png" *ngSwitchCase="messageTypeEnum.warning" />
          <img src="../../../../../../font/iconsrc/info_msg.png" *ngSwitchCase="messageTypeEnum.info" />
          <img src="../../../../../../font/iconsrc/info_msg.png" *ngSwitchCase="messageTypeEnum.performingAction" />
          <img src="../../../../../../font/iconsrc/error_msg.png" *ngSwitchCase="messageTypeEnum.error" />
          <img src="../../../../../../font/iconsrc/success_msg.png" *ngSwitchCase="messageTypeEnum.success" />
        </div>
      </td>
      <td><b style="margin-left: 10px; margin-top: 5px;">{{label}}</b></td>
    </tr>
  </table>

  <div style="margin-top: 10px;" *ngIf="message">
    <b>Message</b>
    <p>{{message}}</p>
  </div>

  <div *ngIf="details">
    <b><a (click)="toggleDetails()" style="cursor: pointer;">{{showDetailsExpanded ? '&lt; Hide details': '&gt; Show
        details'}}</a></b>

    <p *ngIf="showDetailsExpanded" style="margin-top: 10px;">
      {{details}}
    </p>
  </div>
</div>