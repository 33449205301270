import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import { take } from 'rxjs/operators';
import { MessageTemplateComponent } from 'app/modules/shared/message-handler/message-templates.component';
import { ApiMessageService } from './api-message.service';
import { ApiErrorDetails } from 'app/modules/shared/api/api-error-details';
import { MessageTypeEnum } from 'app/modules/shared/message-handler/messageTypeEnum';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(
    private apiMessageService: ApiMessageService,
    private snackBar: MatSnackBar
  ) {
    if (this.apiMessageService) {
      apiMessageService.putMessage.subscribe(m => {
        const snackBarRef = this.dispatchUpdatingMessage();

        m.completed.subscribe(() => {
          snackBarRef.dismiss();
        });

        m.error.subscribe(err => {
          snackBarRef.dismiss();
        });
      });

      apiMessageService.postMessage.subscribe(m => {
        const snackBarRef = this.dispatchCreatingMessage();

        m.completed.subscribe(() => {
          snackBarRef.dismiss();
        });

        m.error.subscribe(err => {
          snackBarRef.dismiss();
        });
      });

      apiMessageService.deleteMessage.subscribe(m => {
        const snackBarRef = this.dispatchDeletingMessage();

        m.completed.subscribe(() => {
          snackBarRef.dismiss();
        });

        m.error.subscribe(err => {
          snackBarRef.dismiss();
        });
      });
    }
  }

  dispatchErrorMessageFromApi(
    apiErrorDetails: ApiErrorDetails,
    modal: boolean = true
  ): MatSnackBarRef<MessageTemplateComponent> {
    if (apiErrorDetails.statusCode === 404) {
      return;
    }

    return this.openSnackbar(
      'Api Error',
      apiErrorDetails.userMessage,
      apiErrorDetails.detailedMessage,
      MessageTypeEnum.error,
      modal
    );
  }

  dispatchErrorMessage(
    message: string,
    modal: boolean = true
  ): MatSnackBarRef<MessageTemplateComponent> {
    return this.openSnackbar(
      'Error',
      message,
      undefined,
      MessageTypeEnum.error,
      modal
    );
  }

  dispatchInfoMessage(
    message: string,
    modal: boolean = true
  ): MatSnackBarRef<MessageTemplateComponent> {
    return this.openSnackbar(
      'Info',
      message,
      undefined,
      MessageTypeEnum.info,
      modal
    );
  }

  dispatchWarningMessage(
    message: string,
    modal: boolean = true
  ): MatSnackBarRef<MessageTemplateComponent> {
    return this.openSnackbar(
      'Warning',
      message,
      undefined,
      MessageTypeEnum.warning,
      modal
    );
  }

  dispatchSuccessMessage(
    message: string,
    modal: boolean = true
  ): MatSnackBarRef<MessageTemplateComponent> {
    return this.openSnackbar(
      'Success',
      message,
      undefined,
      MessageTypeEnum.success,
      modal
    );
  }

  dispatchUpdatingMessage(
    modal: boolean = false
  ): MatSnackBarRef<MessageTemplateComponent> {
    return this.openSnackbar(
      'Updating...',
      undefined,
      undefined,
      MessageTypeEnum.performingAction,
      modal
    );
  }

  dispatchCreatingMessage(
    modal: boolean = false
  ): MatSnackBarRef<MessageTemplateComponent> {
    return this.openSnackbar(
      'Creating...',
      undefined,
      undefined,
      MessageTypeEnum.performingAction,
      modal
    );
  }

  dispatchDeletingMessage(
    modal: boolean = false
  ): MatSnackBarRef<MessageTemplateComponent> {
    return this.openSnackbar(
      'Deleting...',
      undefined,
      undefined,
      MessageTypeEnum.performingAction,
      modal
    );
  }

  private openSnackbar(
    label: string,
    message: string,
    details: string,
    messageType: MessageTypeEnum,
    dismissable: boolean
  ): MatSnackBarRef<MessageTemplateComponent> {
    const snackBarRef = this.snackBar.openFromComponent(
      MessageTemplateComponent,
      { duration: 1000000, verticalPosition: 'top' }
    );
    snackBarRef.instance.label = label;
    snackBarRef.instance.message = message;
    snackBarRef.instance.details = details;
    snackBarRef.instance.messageType = messageType;
    snackBarRef.instance.dismissable = dismissable;
    snackBarRef.instance.dismiss.pipe(take(1)).subscribe(() => {
      snackBarRef.dismiss();
    });

    return snackBarRef;
  }
}
