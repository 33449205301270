import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';

import { ApiProxyService } from 'app/services/api.service';
import { map } from 'rxjs/operators';
import { User } from 'app/shared/model/user';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { concat } from 'rxjs/operators';
import { Device } from 'app/shared/model/device';
import { Agent } from 'app/shared/model/agent';
import { OrderService } from './order-service';

@Injectable({
  providedIn: 'root'
})
export class DeviceService implements OnDestroy {
  devices: Device[] = [];
  selectedDevice: Device;
  selectedDeviceId = '';
  selectedAgent: Agent;
  fetchPollingOrdersSubscription: Subscription;
  constructor(
    private router: Router,
    private apiService: ApiProxyService,
    private authService: AuthService,
    private orderService: OrderService
  ) { }

  ngOnDestroy(): void {
    if (this.fetchPollingOrdersSubscription) {
      this.fetchPollingOrdersSubscription.unsubscribe();
    }
  }

  fetchDevices(agent: Agent): Observable<void> {
    if (!agent) {
      return of(undefined);
    }
    return this.apiService
      .get<Device[]>(environment.apiUrl + 'api/agent/' + agent.id + '/devices')
      .pipe(
        map(devices => {
          this.devices = devices.filter(d => d.isOnline);
        })
      );
  }

  initializeDevicePolling() {
    if (!this.orderService.pollOrderInterval) {
      this.orderService.pollOrderInterval = window.setInterval(() => {
        this.fetchDevices(this.selectedAgent).subscribe((u) => {
        });
        if (this.orderService.pollingOrders.length === 0) {
          return;
        }
        if (this.orderService.pollingOrders.length === 0) {
          return;
        }
        this.fetchPollingOrdersSubscription = this.orderService
          .fetchPollingOrders(this.selectedAgent)
          .subscribe((o) => { });
      }, 1000 * 5);
    }
  }
}
