import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiProxyService } from 'app/services/api.service';
import { map } from 'rxjs/operators';
import { User } from 'app/shared/model/user';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  users: User[];

  constructor(
    private router: Router,
    private apiService: ApiProxyService,
    private authService: AuthService
  ) { }

  fetchUsers(): Observable<void> {
    return this.apiService.get<User[]>(environment.apiUrl + 'api/user').pipe(
      map((users) => {
        this.users = users;
      })
    );
  }

  updateUsers(users: User[]): Observable<void> {
    return this.apiService
      .put<User[]>(environment.apiUrl + 'api/user', users)
      .pipe(map((_) => { }));
  }

  register(user: User): Observable<void> {
    return this.apiService
      .post<User>(environment.apiUrl + 'api/user', user)
      .pipe(map((_) => { }));
  }

  removeUser(userId: string): Observable<void> {
    return this.apiService
      .delete<User>(environment.apiUrl + 'api/user/deleteUser?id=' + userId)
      .pipe(map((user) => { }));
  }

  checkUserNameExists(userModel: UserModel): Observable<UserModel> {
    const data = this.apiService
      .post<UserModel>(
        environment.apiUrl + 'api/user/CheckUserNameExists',
        userModel
      )
      .pipe();
    return data;
  }

  resetPassword(userModel: UserModel): Observable<UserModel> {
    const data = this.apiService
      .post<UserModel>(
        environment.apiUrl + 'api/user/ResetPasswordByEmail',
        userModel
      )
      .pipe();
    return data;
  }

  changePassword(
    passwordModel: ChangePasswordModel
  ): Observable<ChangePasswordModel> {
    const data = this.apiService
      .put<ChangePasswordModel>(
        environment.apiUrl + 'api/user/UpdateUserPassword',
        passwordModel
      )
      .pipe();
    return data;
  }
}

export class UserModel {
  email: string;
}

export class ChangePasswordModel {
  id: string;
  password: string;
  newPassword: string;
}

export class NewPasswordResponse {
  isSuccessful: boolean;
}
