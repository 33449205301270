<div *ngIf="!isInitialized" style="margin-left: 40%; margin-top: 15%">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="isInitialized">
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event)" id="filter-data" class="form-control" type="text"
            autocomplete="off" placeholder="Search by Order Category Name or Agent Machine Name" #input />
        <button class="btn btn-primary" (click)="downloadTestReport()">
            Download Test Report
        </button>
    </mat-form-field>

    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="orderCategoryName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Order Category Name
                </th>
                <td mat-cell *matCellDef="let order">
                    {{ order.orderCategory.name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="agentMachineName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Agent Machine Name
                </th>
                <td mat-cell *matCellDef="let order">{{ order.agent.machineName }}</td>
            </ng-container>

            <!-- <div *ngIf="order.parameters" style="margin-top: 5px">
        <app-polling-order [order]="order"></app-polling-order>
      </div> -->

            <ng-container matColumnDef="deviceName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Device Name</th>
                <td mat-cell *matCellDef="let order">
                    {{ order.device.name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="orderParams">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Parameters</th>
                <td mat-cell *matCellDef="let order">
                    <app-polling-my-order-parameters [order]="order"></app-polling-my-order-parameters>
                </td>
            </ng-container>

            <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
                <td mat-cell *matCellDef="let order">
                    {{ order.createdAt | date: "yyyy-MM-dd HH:mm" }}
                </td>
            </ng-container>


            <ng-container matColumnDef="actions" id="order-actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let order">
                    <mat-icon *ngIf="!order.isComplete" svgIcon="arrow-repeat"></mat-icon>
                    <mat-icon *ngIf="order.isPassing && order.isComplete" svgIcon="check-circle"></mat-icon>
                    <mat-icon *ngIf="!order.isPassing && order.isComplete" svgIcon="exclamation-circle"></mat-icon>
                    <a *ngIf="order.isComplete" [routerLink]="['/order', order.id]">
                        <img title="click to see details" src="font/iconsrc/link.png"
                            style="width: 20px; margin-left: 5px" />
                    </a>
                    <mat-icon *ngIf="!order.isComplete" svgIcon="delete-cross" style="cursor: pointer;"
                        (click)="deleteOrder(order)"></mat-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="download">
                <th mat-header-cell *matHeaderCellDef> Download </th>
                <td mat-cell *matCellDef="let order">
                    <input type="checkbox" [value]="order.id" [checked]="false" (change)="onChangeSelect($event)" />
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let order; columns: displayedColumns"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">
                    No data matching the filter "{{ input.value }}"
                </td>
            </tr>
        </table>

        <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="10"
            [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons>
        </mat-paginator>
    </div>
</div>
