<form class="col-md-6">
  <div class="form-group">
    <label for="exampleInputEmail1">Email address</label>
    <input [(ngModel)]="username" name="username" type="email" class="form-control" id="exampleInputEmail1"
      aria-describedby="emailHelp" placeholder="Enter email" (blur)="checkUserName($event.target.value);" />
    <span class="text-danger" *ngIf="!userNameExist">
      Invalid Email address
    </span>
  </div>
  <div class="form-group">
    <label for="exampleInputPassword1">Password</label>
    <input [(ngModel)]="password" name="password" type="password" class="form-control" id="exampleInputPassword1"
      placeholder="Password" />
  </div>
  <button type="submit" class="btn btn-primary" (click)="login(username)">
    Submit
  </button>
  <span style="margin-left: 5px;">Forgot password? <a class="" routerLink="/reset">
      Reset password</a></span>
</form>