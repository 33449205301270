<div class="col-sm-6">
    <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" class="card-body">
        <h2>Reset password</h2>
        <label class="block-label" style="width: 100%;">
            <span>E-mail</span>
            <input type="text" class="form-control" formControlName="email" id="email" required
                (blur)="checkUserName($event.target.value);">
            <span class="text-danger" *ngIf="!userNameExist">
                Invalid E-mail
            </span> <br>
        </label>
        <div class="">
            <a class="btn btn-primary" routerLink="/login" style="color:white;">Go to Login</a>
            <button class="btn btn-primary float-right">Reset</button>
        </div>
    </form>
</div>