<h3 style="margin: 0 0 0 0;">
    {{ order.createdAt | date: "yyyy-MM-dd HH:mm" }}
</h3>
<h2 style="margin: 0 0 0 0;">
    {{ order.orderCategory.name }}
    <mat-icon *ngIf="!this.order.isComplete" svgIcon="arrow-repeat"></mat-icon>
    <mat-icon *ngIf="this.order.isPassing && this.order.isComplete" svgIcon="check-circle"
        style="width: 20px;"></mat-icon>
    <mat-icon *ngIf=" !this.order.isPassing && this.order.isComplete" svgIcon="exclamation-circle"
        style="width: 20px; margin-left: 5px;"></mat-icon>
    <a *ngIf="this.order.isComplete" [routerLink]="['/order', this.order.id]">
        <img title="click to see details" src="font/iconsrc/link.png" style="width: 20px; margin-left: 5px;" />
    </a>
    <button *ngIf="!this.order.isComplete" mat-button (click)="deleteOrder()"
        style="background-color: Transparent; border: none; outline: none;">
        <b> <a id="deleteBtn-spec" style="cursor: pointer;"> &times;
            </a> </b>
    </button>
</h2>
<h5 style="margin: 0 0 0 0;">{{ order.device.name }}</h5>
<table *ngIf="this.order.parameters.length > 2" class="table" style="border-collapse: collapse;">
    <thead>
        <tr>
            <th>Parameter</th>
            <th>
                <b>Value</b>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let param of this.parameterKeys">
            <td>{{ param }}</td>
            <td>
                <b>{{ this.parameters[param] }}</b>
            </td>
        </tr>
    </tbody>
</table>