import { Component, OnInit } from '@angular/core';
import { UserService, UserModel } from 'app/services/user-service';
import { User } from 'app/shared/model/user';
import { Router } from '@angular/router';
import { AuthService } from 'app/modules/authentication/services/auth.service';

@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.css'],
})
export class RegistrationPageComponent implements OnInit {
  public userNameExist = false;
  user: User = new User();
  confirmPassword = '';

  constructor(
    private userService: UserService,
    private router: Router,
    private authService: AuthService
  ) { }
  ngOnInit(): void { }

  checkUserName(email: string) {
    const userModel: UserModel = { email };
    this.userService.checkUserNameExists(userModel).subscribe((data) => {
      this.userNameExist = this.assignValueUserNameExist(data);
    });
  }

  assignValueUserNameExist(data: any) {
    return data === true ? true : false;
  }

  register() {
    this.userService.register(this.user).subscribe({
      next: (_arg) => {
        this.authService.login(this.user.email, this.user.password).subscribe({
          next: (_arg2) => {
            this.authService.getProfile().subscribe({
              next: (_arg3) => {
                this.router.navigate(['/home']);
              },
            });
          },
        });
      },
    });
  }
}
