import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AuthService } from 'app/modules/authentication/services/auth.service';
import { OrderService } from 'app/services/order-service';
import { Device } from 'app/shared/model/device';
import { Order } from 'app/shared/model/order';
import { User } from 'app/shared/model/user';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import * as FileSaver from 'file-saver';

import { OrderTableDataSource } from './order-table-datasource';
import { formatDate, DatePipe } from '@angular/common';

@Component({
  selector: 'app-my-orders-page',
  styleUrls: ['./my-orders-page.component.css'],
  templateUrl: './my-orders-page.component.html',
})

export class MyOrdersPageComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  user: User;
  selectedDevice: Device;
  selectedDeviceId = '';

  displayedColumns: string[] = ['orderCategoryName', 'agentMachineName', 'deviceName', 'orderParams', 'createdAt', 'actions', 'download'];
  dataSource: MatTableDataSource<Order>;
  orderTableDataSource: OrderTableDataSource;
  fetchOrderSubscription: Subscription;
  isInitialized: boolean;
  arr: { id: string }[] = [];
  constructor(
    private authService: AuthService,
    private orderService: OrderService,
    public datepipe: DatePipe
  ) { }
  ngOnInit() {
    if (this.authService.tdqureUser) {
      this.initializeComponent(this.authService.tdqureUser);
    } else {
      this.authService.userSubject.pipe(take(1)).subscribe(() => {
        this.initializeComponent(this.authService.tdqureUser);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.fetchOrderSubscription) {
      this.fetchOrderSubscription.unsubscribe();
    }
  }

  initializeComponent(user: User) {
    this.user = user;
    this.orderTableDataSource = new OrderTableDataSource(this.paginator, this.sort);

    this.fetchOrderSubscription = this.orderService.fetchAllOrders(this.user).subscribe(() => {

      this.dataSource = new MatTableDataSource(this.orderService.allOrders);
      this.orderTableDataSource.setOrders(this.orderService.allOrders);

      this.dataSource.filterPredicate = (data, filterPr: string) =>
        (data.orderCategory.name != null && data.orderCategory.name.toLowerCase().includes(filterPr)) ||
        (data.agent.machineName != null && data.agent.machineName.toLowerCase().includes(filterPr));

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.dataSource.sortingDataAccessor = ((item, sortHeaderId: string) => {
        switch (sortHeaderId) {
          case 'orderCategoryName': return item.orderCategory.name;
          case 'agentMachineName': return item.agent.machineName;
          case 'deviceName': return item.device.name;

          default: return item[sortHeaderId];
        }
      });
    });

    this.isInitialized = true;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteOrder(order: Order) {
    this.orderService.deleteOrder(order).subscribe(() => {
      this.orderService.removeFromOrders(order);
      this.initializeComponent(this.authService.tdqureUser);
    });
  }
  onChangeSelect($event) {
    const id = $event.target.value;
    this.arr.push({ id });
  }

  downloadTestReport() {
    const fileDataFromOrder = [];
    let report = '';
    const table = '<table  style="border:1px solid black;width:100%;color:black;border-collapse:collapse">';
    const tableHeaderRow = '<tr style="border:1px solid black;background-color:#033a6a;color:white;height:30px">';
    const tableHeader = '<th>Order Category</th><th>Device Name</th><th>Name</th><th>Request</th><th> Paasing </th> <th>Message</th></tr>';
    this.orderService.allOrders
      .forEach(
        commands => {
          this.arr.forEach(e => {
            if (e.id === commands.id) {
              fileDataFromOrder.push(commands.commands);
            }
          }
          );
        }
      );
    fileDataFromOrder.forEach((e, idx, array) => {
      e.forEach(e1 => {
        const name = e1.name;
        const request = e1.requestAsString;
        const result = String(e1.isPassing);
        let message = e1.message;
        const orderCategory = e1.order.orderCategory.name;
        const deviceName = e1.order.device.name;
        if (message === null) {
          message = '';
        }
        report += '<tr style="border:1px solid black;text-align:center"><td style="border:1px solid black;width:15%">' + orderCategory + '</td><td style="border:1px solid black;width:15%">'
          + deviceName + '</td><td style="border:1px solid black;width:25%">' + name + '</td><td style="border:1px solid black;width:15%">'
          + request + '</td><td td style="border:1px solid black;width:10%">' + result + '</td><td td style="border:1px solid black;width:20%">' + message + '</td></tr>';
      }
      );
      if (!(idx === array.length - 1)) {
        report += '<tr style= "height:20px !important;background-color: #FFFFFF;"><td colspan="6"></td></tr>' + table + tableHeaderRow + tableHeader;
      }
    });
    const completeReport = table + tableHeaderRow + tableHeader + report + '</table>';
    FileSaver.saveAs(new Blob([completeReport], { type: 'text/xml;charset=iso-8859-1' }), 'report.html');
  }


  // downloadTestReport() {
  //   let deviceName;
  //   const fileDataFromOrder = [];
  //   let report = '';
  //   const table = '<table  style="border:1px solid black;width:80%;color:black;border-collapse:collapse;margin-left:auto;margin-right:auto;margin-bottom:10px;">';
  //   const tableHeaderRow = '<tr style="border:1px solid black;background-color:#033a6a;color:white;height:30px">';
  //   const tableHeader = '<th>Item</th><th>Description</th></tr>';
  //   this.orderService.allOrders
  //     .forEach(
  //       commands => {
  //         this.arr.forEach(e => {
  //           if (e.id === commands.id) {
  //             fileDataFromOrder.push(commands.commands);
  //           }
  //         }
  //         );
  //       }
  //     );
  //   fileDataFromOrder.forEach((e, idx, array) => {
  //     e.forEach(e1 => {
  //       const name = e1.name;
  //       const request = e1.requestAsString;
  //       //const result = String(e1.isPassing);
  //       const result = e1.isPassing === true ? 'Passed' : 'Failed';
  //       let message = e1.message;
  //       const orderCategory = e1.order.orderCategory.name;
  //       const version = e1.order.orderCategory.version;
  //       deviceName = e1.order.device.name;
  //       if (message === null) {
  //         message = '';
  //       }
  //       report += '<tr style="border:1px solid black;text-align:center"> '
  //         + '<td style="border:1px solid black width:15%;"> Order Category </td><td style="border:1px solid black; width:75%;"> Order Category = '
  //         + orderCategory + ' ,  Dignostic Type = ' + e1.order.orderCategory.orderDiagnosticType + ',  Version = ' + e1.order.orderCategory.version + ' </td>'
  //         + '</tr>'
  //         + '<tr style="border:1px solid black;text-align:center"><td style="border:1px solid black; width:15%;"> Device Name  </td><td style="border:1px solid black; width:75%;">'
  //         + deviceName + '</td>'
  //         + '</tr>'
  //         + '<tr style="border:1px solid black;text-align:center"><td style="border:1px solid black; width:15%;">  Test Name  </td><td style="border:1px solid black; width:75%;">'
  //         + name + '</td>'
  //         + '</tr>'
  //         + '<tr style="border:1px solid black;text-align:center"><td style="border:1px solid black; width:15%;">  Request  </td><td style="border:1px solid black; width:75%;">'
  //         + request + '</td>'
  //         + '</tr>'
  //         + '<tr style="border:1px solid black;text-align:center"><td style="border:1px solid black; width:15%;">  Test Result  </td><td style="border:1px solid black; width:75%;">'
  //         + result + '</td>'
  //         + '</tr>'
  //         + '<tr style="border:1px solid black;text-align:center"><td style="border:1px solid black; width:15%;">  Message  </td><td style="border:1px solid black; width:75%;">'
  //         + message + '</td>'
  //         + '</tr>'
  //         + '<tr style="border:1px solid black;text-align:center"><td style="border:1px solid black; width:15%;">  Test Date  </td><td style="border:1px solid black; width:75%;">'
  //         + this.datepipe.transform(e1.order.createdAt, 'yyyy-MM-dd HH:mm') + '</td>'
  //         + '</tr>'
  //         + '<tr style="border:1px solid black;text-align:center"><td style="border:1px solid black; width:15%;">  User Email   </td><td style="border:1px solid black; width:75%;">'
  //         + e1.order.user.email + '</td>'
  //         + '</tr>';
  //     }
  //     );
  //     if (!(idx === array.length - 1)) {
  //       report += table + tableHeaderRow + tableHeader;
  //     }
  //   });
  //   const currentDate = new Date();
  //   const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
  //   const fileName = deviceName + '-' + cValue;
  //   const completeReport = table + tableHeaderRow + tableHeader + report + '</table>';
  //   FileSaver.saveAs(new Blob([completeReport], { type: 'text/xml;charset=iso-8859-1' }), fileName + '.html');
  // }
}
