import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ApiErrorDetails } from './api-error-details';

export class ApiMessage {

    completed: Observable<void>;
    error: Observable<ApiErrorDetails>;
    private completedSubject: Subject<void>;
    private errorSubject: Subject<ApiErrorDetails>;

    constructor() {
        this.completedSubject = new Subject<void>();
        this.completed = this.completedSubject.asObservable();

        this.errorSubject = new Subject<ApiErrorDetails>();
        this.error = this.errorSubject.asObservable();
    }

    sendCompleted() {
        this.completedSubject.next();
    }

    sendError(apiErrorDetails: ApiErrorDetails) {
        this.errorSubject.next(apiErrorDetails);
    }
}