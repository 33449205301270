import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthCallbackComponent } from './modules/authentication/auth-callback/auth-callback.component';
import { AuthorizationFailedComponent } from './modules/authentication/authorization-failed/authorization-failed.component';
import { InstallationPageComponent } from './pages/installation-page/installation-page.component';
import { RunTestsPageComponent } from './pages/run-tests-page/run-tests-page.component';
import { RegistrationPageComponent } from './pages/registration-page/registration-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { UserManagementPageComponent } from './pages/user-management-page/user-management-page.component';
import { OrderPageComponent } from './pages/order-page/order-page.component';
import { MyOrdersPageComponent } from './pages/my-orders-page/my-orders-page.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { ChangepasswordComponent } from './pages/changepassword/changepassword.component';
import { InformationPageComponent } from './pages/information-page/information-page.component';
import { AuthguardService } from './modules/authentication/services/authguard.service';

const routes: Routes = [
  {
    path: 'auth-callback',
    component: AuthCallbackComponent,
  },
  {
    path: 'authorization-failed',
    component: AuthorizationFailedComponent,
  },
  {
    path: 'run',
    canActivate: [AuthguardService],
    component: RunTestsPageComponent,
  },
  {
    path: 'installation',
    canActivate: [AuthguardService],
    component: InstallationPageComponent,
  },
  {
    path: 'my-orders',
    canActivate: [AuthguardService],
    component: MyOrdersPageComponent,
  },
  {
    path: 'register',
    component: RegistrationPageComponent,
  },
  {
    path: 'home',
    component: HomePageComponent,
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'reset',
    component: ResetpasswordComponent,
  },
  {
    path: 'changepassword',
    canActivate: [AuthguardService],
    component: ChangepasswordComponent,
  },
  {
    path: 'info',
    canActivate: [AuthguardService],
    component: InformationPageComponent,
  },
  {
    path: 'order/:orderId',
    canActivate: [AuthguardService],
    component: OrderPageComponent,
  },
  {
    path: 'users',
    canActivate: [AuthguardService],
    component: UserManagementPageComponent,
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
