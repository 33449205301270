import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../modules/authentication/services/auth.service';
import { User } from 'app/shared/model/user';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  router: Router;
  user: User;
  isUserLoggedIn = false;

  //need to commit
  constructor(router: Router, private authService: AuthService) {
    this.router = router;
    authService.userSubject.subscribe((u) => {
      if (u !== null) {
        this.isUserLoggedIn = true;
      }
      this.user = u;
    });
  }

  ngOnInit() { }

  logout() {
    localStorage.removeItem('token');
    sessionStorage.removeItem('loggedinUser');
  }
}
