import { Agent } from './agent';

export class User {
  public email: string;
  public isAdmin: boolean;
  public id: string;
  public isApproved: boolean;
  public company: string;
  public agents: Agent[];
  public password: string;
}
