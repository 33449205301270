<app-message-handler></app-message-handler>
<!-- <div *ngIf="isChrome == false && showBrowserWarning" class="alert alert-warning browser-warning" (click)="closeBrowserWarning();"
    [@fadeout]>
    <p>You are not using Google Chrome. We strongly recommend Google Chrome for the best performance and user experience! Click
        to close this warning.</p>
</div> -->
<c-theme name="scania" global="true"></c-theme>

<app-navbar></app-navbar>
<c-content class="content">
  <section class="app tdqure-content">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </section> </c-content
><c-footer></c-footer>
